import moment from 'moment';


const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000'
const API_URL = `${BASE_URL}/api/`;

export const API_CLIENTES_URL = API_URL + 'clientes/';
export const API_CUENTAS_URL = API_URL + 'auth/';
export const API_USERS_URL = API_URL + 'users/'
export const API_USUARIOS_URL = API_URL + 'usuarios/'
export const API_CLIENTES_BOOKMAKERS_URL = API_URL + 'clientes/bookmakers/';
export const API_BANCO_URL = API_URL + 'clientes/banco/';
export const API_MONEDEROS_URL = API_URL + 'clientes/monederos/';
export const API_AJUSTES_URL = API_URL + 'ajustes/';
export const API_PERMISSIONS_URL = API_URL + 'permissions/';
export const API_PERMISSIONS_GROUP_URL = API_URL + 'permissions_group/';
export const API_COMERCIALES_URL = API_URL + 'comerciales/';
export const API_APUESTAS_URL = API_URL + 'apuestas/';
export const API_CONEXIONESPROV_URL = API_URL + 'conexionesprov/';
export const API_RASPBERRIES_URL = API_URL + 'raspberries/';
export const API_BANCO_OPCIONES_URL = API_URL + 'banco_opciones/'
export const API_BOOKMAKERS_URL = API_URL + 'bookmakers/';
export const API_PRIORIDADES_URL = API_URL + 'prioridades/';
export const API_MONEDEROS_OPCIONES_URL = API_URL + 'monederos_opciones/';
export const API_CONTABILIDADES_URL = API_URL + 'contabilidades/';
export const API_ESTRATEGIAS_URL = API_URL + 'estrategias/bookmakers/';
export const API_ASIGNACIONES_URL = API_URL + 'estrategias/asignaciones/';
export const API_ESTIMACIONES_URL = API_URL + 'estrategias/estimaciones/';
export const API_OBJETIVO_URL = API_URL + 'estrategias/objetivos/';
export const API_NOTIFICACIONES_URL = API_URL + 'notificaciones/';
export const API_PROFILE_URL = API_URL + 'profile/';
export const API_ENTIDADES_URL = API_URL + 'entidades/';

export const ADMINISTRADOR = 'ADMINISTRADOR';
export const SUPERUSER = 'SUPERUSER';
export const TRADER = 'TRADER';
export const GESTOR = 'GESTOR';
export const SHORT = 'SHORT';
export const LARGE = 'LARGE';

export const URGENTE = 1;

// Headers
export const HOME = 'Home'
export const ESTRATEGIAS = 'Estrategias'
export const ESTRATEGIASACTIVO = 'EstrategiasActivo'
export const ESTRATEGIASHISTORICO = 'EstrategiasHistorico'
export const ESTRATEGIASASIGNACION = 'EstrategiasAsignacion'
export const CUENTAS = 'Cuentas'
export const CUENTASCLIENTES = 'CuentasClientes'
export const CUENTASPERFIL = 'CuentasPerfil'
export const CONTABILIDAD = 'Contabilidad'
export const CONTABILIDADACTIVO = 'ContabilidadActivo'
export const CONTABILIDADHISTORICO = 'ContabilidadHistorico'
export const APUESTAS = 'Apuestas'
export const CONEXIONP = 'ConexionP'
export const RESUMEN = 'Resumen'
export const RESUMENGESTOR = 'ResumenGestor'
export const RESUMENTRADER = 'ResumenTrader'
export const RESUMENADMINISTRADOR = 'ResumenAdministrador'

const COMMON_HEADER = [HOME]
const TRADER_HEADER = [...COMMON_HEADER, ESTRATEGIAS, ESTRATEGIASACTIVO, ESTRATEGIASHISTORICO, RESUMENTRADER]
const GESTOR_HEADER = [...COMMON_HEADER, CUENTAS, CUENTASCLIENTES, CONTABILIDAD, CONTABILIDADACTIVO, CONTABILIDADHISTORICO, RESUMENGESTOR]
const CLIENTE_HEADER = [...COMMON_HEADER, CUENTAS, CUENTASPERFIL, CONTABILIDAD, CONTABILIDADACTIVO, CONTABILIDADHISTORICO, RESUMENGESTOR]
const ADMINISTRADOR_HEADER = [...COMMON_HEADER, CONTABILIDAD, CONTABILIDADACTIVO, CONTABILIDADHISTORICO, ESTRATEGIAS, ESTRATEGIASASIGNACION, ESTRATEGIASHISTORICO, APUESTAS, CONEXIONP, RESUMENADMINISTRADOR]
const SUPERADMIN_HEADER = [...COMMON_HEADER, ...TRADER_HEADER, ...GESTOR_HEADER, ...ADMINISTRADOR_HEADER]
export const GROUPS_HEADER = {
    TRADER: TRADER_HEADER,
    GESTOR: GESTOR_HEADER,
    ADMINISTRADOR: ADMINISTRADOR_HEADER,
    SUPERUSER: SUPERADMIN_HEADER,
    CLIENTE: CLIENTE_HEADER
}


export const EN_PREPARACION = 'EN PREPARACION';
export const SOLICITADA = 'SOLICITADA';
export const EN_PROGRESO = 'EN PROGRESO';
export const EN_REVISION = 'EN REVISION';
export const COMPLETADA = 'COMPLETADA';


export const nowDatetime = moment().format().slice(0, 19).replace('T', ' ');