export const USUARIO_LOADING = 'USUARIO_LOADING'; // added
export const USUARIO_LOADED = 'USUARIO_LOADED'; // added
export const AUTH_ERROR = 'AUTH_ERROR'; // added
// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'; // added
// export const REGISTER_FAIL = 'REGISTER_FAIL'; // added
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'; // added
export const LOGIN_FAIL = 'LOGIN_FAIL'; // added
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'; // added
export const LIST_USERS_BY_GROUP_LOADING = 'LIST_USERS_BY_GROUP_LOADING'
export const LIST_USERS_BY_GROUP = 'LIST_USERS_BY_GROUP'
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LIST_ENTIDADES = 'LIST_ENTIDADES'
export const LIST_ENTIDADES_LOADING = 'LIST_ENTIDADES_LOADING';
export const REGISTER = 'REGISTER';
export const CODE_VALIDATION = 'CODE_VALIDATION';
