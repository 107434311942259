import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import Header from '../common/Header';
import BreadCrumbs from '../common/BreadCrumbs';
import Container from 'reactstrap/lib/Container';
import { listHistoricContabilidadesByGestor, listHistoricContabilidadesByGestorByCanperId, listCanperIdsByGestor } from "../../redux/actions/contabilidades";
import ListResumenGestor from './ListResumenGestor';
import Spinner from 'reactstrap/lib/Spinner';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Row } from 'reactstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


function ResumenGestorView(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [canperId, setCanperId] = React.useState(moment().format("MMMYYYY").toUpperCase());


    useEffect(() => {
        !props.canperIds && props.listCanperIdsByGestor(props.userId);
        !props.contabilidades && props.listHistoricContabilidadesByGestorByCanperId(props.userId, canperId);
    }, []);

    const onChangeCanperId = (event) => {
        const canperId = event.target.value;
        setCanperId(canperId);
        canperId === 'ALL' ? props.listHistoricContabilidadesByGestor(props.userId) : props.listHistoricContabilidadesByGestorByCanperId(props.userId, canperId);
    };

    return <>
        <Header />
        <Container fluid>
            <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': `/#/resumen_g`, 'name': t('estrategias:summary.label'), 'active': true},
            ]}/>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Id</InputLabel>
                <Select
                    labelId="canper-label"
                    id="canper-id"
                    value={canperId}
                    onChange={onChangeCanperId}
                    label="Id"
                >
                    {(props.canperIds || []).map(e => 
                        <MenuItem value={e}>{e}</MenuItem>)
                    }
                    <MenuItem value='ALL'>{t('common:all.label').toUpperCase()}</MenuItem>
                </Select>
            </FormControl>
            {props.isLoading === false && props.permissions ? <ListResumenGestor contabilidades={props.contabilidades}/> :
            <Row><Spinner style={{marginTop: '30px', marginLeft: '100px'}} animation="border" color="primary"/></Row>}
        </Container>
    </>;

}

const mapStateToProps = state => ({
    canperIds: state.contabilidades.canperIds,
    contabilidades: state.contabilidades.contabilidades_historico,
    isLoading: state.contabilidades.isLoadingHistoric,
    permissions: state.cuentas.permissions.contabilidad,
    userId: state.cuentas.user.id
});

export default connect(mapStateToProps, {listCanperIdsByGestor, listHistoricContabilidadesByGestor, listHistoricContabilidadesByGestorByCanperId, })(ResumenGestorView);