import {combineReducers} from 'redux';
import clientes from './clientes';
import cuentas from './cuentas';
import errores from './errores';
import mensajes from './mensajes';
import opciones from './opciones';
import comerciales from './comerciales';
import raspberries from './raspberries';
import apuestas from './apuestas';
import bookmakers from './bookmakers';
import contabilidades from './contabilidades';
import estrategias from './estrategias';
import usuarios from './usuarios';
import conexionesprov from './conexionesprov';
import notificaciones from './notificaciones';
import { LOGOUT_SUCCESS } from '../types/cuentas';

const appReducer = combineReducers({
    clientes,
    cuentas,
    errores,
    mensajes,
    opciones,
    comerciales,
    raspberries,
    apuestas,
    bookmakers,
    contabilidades,
    estrategias,
    usuarios,
    conexionesprov,
    notificaciones
});

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
      state = undefined
    }
  
    return appReducer(state, action)
  }
