import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEs from './locales/es/common.json'
import commonEn from './locales/en/common.json'
import bancoEs from './locales/es/banco.json'
import bancoEn from './locales/en/banco.json'
import cuentasEs from './locales/es/cuentas.json'
import cuentasEn from './locales/en/cuentas.json'
import clientesEs from './locales/es/clientes.json'
import clientesEn from './locales/en/clientes.json'
import bookmakersEs from './locales/es/bookmakers.json'
import bookmakersEn from './locales/en/bookmakers.json'
import monederosEs from './locales/es/monederos.json'
import monederosEn from './locales/en/monederos.json'
import ficherosEs from './locales/es/ficheros.json'
import ficherosEn from './locales/en/ficheros.json'
import comercialesEs from "./locales/es/comerciales.json"
import comercialesEn from "./locales/en/comerciales.json"
import apuestasEs from "./locales/es/apuestas.json"
import apuestasEn from "./locales/en/apuestas.json"
import raspberriesEn from "./locales/en/raspberries.json"
import raspberriesEs from "./locales/es/raspberries.json"
import conexionesEs from "./locales/es/conexiones.json"
import conexionesEn from "./locales/en/conexiones.json"
import rangosEs from "./locales/es/rangos.json"
import rangosEn from "./locales/en/rangos.json"
import ingresosEn from "./locales/en/ingresos.json"
import ingresosEs from "./locales/es/ingresos.json"
import conexionesClientesEs from "./locales/es/conexiones_clientes.json"
import conexionesClientesEn from "./locales/en/conexiones_clientes.json"
import contabilidadesEn from "./locales/en/contabilidades.json"
import contabilidadesEs from "./locales/es/contabilidades.json"
import estrategiasEs from "./locales/es/estrategias.json"
import estrategiasEn from "./locales/en/estrategias.json"
import notificacionesEs from "./locales/es/notificaciones.json"
import notificacionesEn from "./locales/en/notificaciones.json"
import conexionesprovEs from "./locales/es/conexionesprov.json"
import conexionesprovEn from "./locales/en/conexionesprov.json"


i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        whitelist: ["en", "es"],
        fallbackLng: 'en',
        keySeparator: false,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: { 
            en: {
                cuentas: cuentasEn,
                clientes: clientesEn,
                common: commonEn,
                banco: bancoEn,
                bookmakers: bookmakersEn,
                monederos: monederosEn,
                ficheros: ficherosEn,
                comerciales: comercialesEn,
                raspberries: raspberriesEn,
                apuestas: apuestasEn,
                conexiones: conexionesEn,
                rangos: rangosEn,
                ingresos: ingresosEn,
                conexiones_clientes: conexionesClientesEn,
                contabilidades: contabilidadesEn,
                estrategias: estrategiasEn,
                notificaciones: notificacionesEn,
                conexionesprov: conexionesprovEn
            },
            es: {
                cuentas: cuentasEs,
                clientes: clientesEs,
                common: commonEs,
                banco: bancoEs,
                bookmakers: bookmakersEs,
                monederos: monederosEs,
                ficheros: ficherosEs,
                comerciales: comercialesEs,
                raspberries: raspberriesEs,
                apuestas: apuestasEs,
                conexiones: conexionesEs,
                rangos: rangosEs,
                ingresos: ingresosEs,
                conexiones_clientes: conexionesClientesEs,
                contabilidades: contabilidadesEs,
                estrategias: estrategiasEs,
                notificaciones: notificacionesEs,
                conexionesprov: conexionesprovEs

            }
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;