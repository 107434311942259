import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { listEstrategiasWithEstimaciones, listCanperIds, listEstrategiasWithEstimacionesByCanperId } from "../../../redux/actions/estrategias";
import ListResumenTrader from './ListResumenTrader';
import Spinner from 'reactstrap/lib/Spinner';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


function ResumenTraderView(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [canperId, setCanperId] = React.useState(moment().format("MMMYYYY").toUpperCase());

    useEffect(() => {
        !props.canperIds && props.listCanperIds();
        !props.contabilidades && props.listEstrategiasWithEstimacionesByCanperId(canperId);
    }, []);

    const onChangeCanperId = (event) => {
        const canperId = event.target.value;
        setCanperId(canperId);
        canperId === 'ALL' ? props.listEstrategiasWithEstimaciones() : props.listEstrategiasWithEstimacionesByCanperId(canperId);
    };

    return <>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Id</InputLabel>
            <Select
                labelId="canper-label"
                id="canper-id"
                value={canperId}
                onChange={onChangeCanperId}
                label="Id"
            >
                {(props.canperIds || []).map(e => 
                    <MenuItem value={e}>{e}</MenuItem>)
                }
                <MenuItem value='ALL'>{t('common:all.label').toUpperCase()}</MenuItem>
            </Select>
        </FormControl>
        {props.isLoadingEstrategias === false && props.permissions ? <ListResumenTrader estrategias={props.estrategias}/> :
        <Row><Spinner style={{marginTop: '30px', marginLeft: '100px'}} animation="border" color="primary"/></Row>}
    </>;
}

const mapStateToProps = state => ({
    estrategias: state.estrategias.estimaciones,
    isLoadingEstrategias: state.estrategias.isLoadingEstimaciones,
    canperIds: state.estrategias.canperIds,
    permissions: state.cuentas.permissions.estrategia,
});

export default connect(mapStateToProps, {listEstrategiasWithEstimaciones, listCanperIds, listEstrategiasWithEstimacionesByCanperId})(ResumenTraderView);